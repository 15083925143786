<template>
  <el-dialog
    custom-class="set-city-manage"
    width="1100px"
    title="选择单品"
    :visible="visible"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="cancel"
  >
    <div v-loading="loading">
      <el-row>
        <el-col :span="18">
          <search :type="type" @search="search" />
          <el-divider class="bandProduct-divider" />
          <div class="product-tree">
            <el-tree
              v-if="visible"
              ref="tree"
              :data="data"
              default-expand-all
              icon-class="el-icon-arrow-right"
              show-checkbox
              node-key="id"
              :props="defaultProps"
              :default-checked-keys="defaultKeys"
              @check="selectNode"
            >
              <span slot-scope="scope" class="custom-tree-node">
                <span
                  v-if="type === 2"
                  class="title"
                  :style="data.categoryType ? 'font-weight:600' : ''"
                >
                  {{
                    scope.data.relationProductName
                      ? `${scope.data.productCode}  / ${scope.data.relationProductName} / ${scope.data.priceUnit}`
                      : `${scope.data.categoryName}`
                  }}</span
                >
                <span
                  v-else
                  class="title"
                  :style="scope.data.categoryType ? 'font-weight:600' : ''"
                  >{{
                    scope.data.relationProductName
                      ? `${scope.data.productCode} / ${
                          data.relationServiceType === 1 ? '到店拍摄' : '上门拍摄'
                        } / ${scope.data.relationProductName}`
                      : `${scope.data.categoryName}`
                  }}</span
                >
              </span>
            </el-tree>
          </div>
        </el-col>
        <el-col :span="6">
          <p style="margin-left: 30px; margin-bottom: 10px; margin-top: 0; font-weight: 600">
            已选 {{ selectList.length }}/{{ total }}
          </p>
          <div
            class="city-tags"
            style="padding: 10px; padding-top: 0; padding-left: 30px; height: 500px"
          >
            <el-tag
              v-for="item in selectList"
              :key="item.id"
              closable
              style="margin-right: 10px; margin-bottom: 10px"
              @close="handleDeleteSelect(item)"
            >
              {{ item.productCode }} / {{ item.relationProductName }}
              <span v-if="type === 2">/ {{ item.priceUnit }}</span>
            </el-tag>
          </div>
          <div style="text-align: right; margin-top: 15px">
            <el-button size="small" @click="cancel">取消</el-button>
            <el-button type="primary" size="small" @click="ok">确认</el-button>
          </div>
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>

<script>
import { findConditionList } from '@/api/beforeShoot'
import search from './search.vue'

export default {
  components: {
    search,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Number,
      default: 0,
    },
    productList: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    questionnaireId: {
      type: String,
      default: '',
    },
    isQuestionnaire: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      data: [],
      filterText: '',
      selectList: [],
      total: 0,
      typeList: ['', 'success', 'info', 'warning', 'danger'],
      loading: false,
      defaultKeys: [],
      defaultProps: {
        children: 'productList',
      },
    }
  },
  computed: {
    productListCopy() {
      return [...this.productList]
    },
  },
  watch: {
    visible(val) {
      this.selectList = this.productList
      if (val && this.productList.length) {
        this.$nextTick(() => {
          this.$refs.tree.setCheckedKeys(this.productList.map((e) => e.relationProductId))
        })
      }
    },
    productListCopy: {
      handler(val, old) {
        this.defaultKeys = [...val].map((el) => el.relationProductId)

        this.$forceUpdate()
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    try {
      await this.search([])
      this.selectNode()
    } catch (err) {}
  },
  methods: {
    async search(e) {
      const reqObj = {
        data: {
          ...e,
          questionnaireId: this.questionnaireId,
          categoryType: 2,
          isQuestionnaire: this.isQuestionnaire,
        },
      }

      const res = await findConditionList(reqObj)
      let total = 0
      this.changeTree(res.data)
      res.data.forEach((item) => {
        total += item.productList.length
      })
      this.total = total
      this.data = [...res.data]
    },
    changeTree(list) {
      list.forEach((e) => {
        e.id = e.relationProductId || e.categoryId
        if (e.productList) {
          this.changeTree(e.productList)
        }
      })
    },
    selectNode(e) {
      if (this.multiple) {
        const data = this.$refs.tree.getCheckedNodes()
        return (this.selectList = data.filter((el) => !el.productList))
      }
      this.$refs.tree.setCheckedNodes([])
      if (e.productList?.length) {
        return this.$message({
          type: 'error',
          message: '请选择单品，而不是单品分类！',
        })
      }
      this.$refs.tree.setChecked(e, true)
      this.selectList = [e]
    },
    cancel() {
      this.selectList = []

      this.$emit('cancel')
    },
    ok() {
      this.$emit('ok', this.selectList)
    },
    handleDeleteSelect(item) {
      const index = this.selectList.findIndex((el) => {
        return el.relationProductId === item.relationProductId
      })
      this.selectList.splice(index, 1)
    },
  },
}
</script>

<style lang="scss" scoped>
.product-tree {
  height: 400px;
  overflow: auto;
  .custom-tree-node {
    height: auto;
    width: 100%;
    padding-right: 4px;
    .title {
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      display: inline-block;
      padding: 3px 0;
    }
    .citys {
      height: auto;
      width: 100%;
      .title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        i {
          z-index: 999 !important;
        }
      }
      .cityitems::after {
        content: '';
        clear: both;
      }
      .city {
        padding: 0px 16px;
        height: 30px;
        border-radius: 4px;
        border: 1px solid #e0e0e0;
        text-align: center;
        line-height: 30px;
        position: relative;
        float: left;
        margin: 4px 8px;
        .icon {
          position: absolute;
          right: 0px;
          bottom: 0;
        }
      }
      .city:focus {
        background-color: #f5f7fa;
      }
      .city-isSelect {
        border: 1px solid #2861e2;
      }
    }
  }
}
.product-tree::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  /**/
}
.product-tree::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 5px;
  display: none;
}
.product-tree::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 10px;
}
.city-tags {
  overflow: auto;
}
.city-tags::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  /**/
}
.city-tags::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 5px;
  display: none;
}
.city-tags::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 10px;
}
</style>
