<template>
  <div class="bandProduct-search">
    <el-form
      :model="form"
      class="demo-form-inline"
      :show-message="false"
      size="small"
      label-width="0"
    >
      <el-row :gutter="24">
        <el-col :span="9">
          <el-form-item label prop="productName">
            <el-input
              v-model="form.productName"
              placeholder="请输入产品名称"
              class="form-item-input"
              style="width: 100%"
              size="mini"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label prop="productCode">
            <el-input
              v-model="form.productCode"
              placeholder="请输入产品ID"
              class="form-item-input"
              style="width: 100%"
              size="mini"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label prop="productLevel">
            <el-select
              v-model="form.productLevel"
              style="width: 100%"
              size="mini"
              placeholder="请选择基础产品等级"
            >
              <el-option
                v-for="level in levelList"
                :key="level.dictionaryKey"
                :label="level.dictionaryName"
                :value="level.dictionaryValue"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="9" class="col">
          <el-select
            v-model="form.region"
            placeholder="大区"
            filterable
            class="area-select"
            size="mini"
            style="flex: 1"
          >
            <el-option key="all" label="全部" value></el-option>
            <el-option
              v-for="area in areaList"
              :key="area.regionId"
              :label="area.regionName"
              :value="area.regionId"
            ></el-option>
          </el-select>

          <el-select
            v-model="form.productProvince"
            filterable
            style="flex: 1; margin: 0 4px"
            size="mini"
            placeholder="请选择适用省份"
          >
            <el-option key="all" label="全部" value></el-option>
            <el-option
              v-for="province in selectProvinceList"
              :key="`p${province.districtId}`"
              :label="province.districtName"
              :value="province.districtId"
            ></el-option>
          </el-select>

          <el-select
            v-model="form.productCity"
            style="flex: 1"
            size="mini"
            placeholder="请选择适用城市"
            filterable
          >
            <el-option key="all" label="全部" value></el-option>
            <el-option
              v-for="city in selectCityList"
              :key="`c${city.districtId}`"
              :label="city.districtName"
              :value="city.districtId"
            ></el-option>
          </el-select>
        </el-col>

        <el-col v-if="type !== 2" :span="8">
          <el-form-item label>
            <el-select
              v-model="form.serviceType"
              placeholder="服务类型"
              class="area-select"
              size="mini"
              style="width: 100%"
            >
              <el-option label="到店拍摄" value="1"></el-option>
              <el-option label="上门拍摄" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" class="col">
          <div class="col-tag">
            <el-button
              type="text"
              style="margin-right: 20px"
              @click="tagVisible = true"
              >选择标签</el-button
            >
            <el-tag
              v-for="(tag, index) in form.productTagValues"
              :key="index"
              style="margin-right: 10px; margin-bottom: 10px"
              :style="`color:#000;border:0;background-color:${tag.colour}`"
              >#{{ tag.name }}</el-tag
            >
          </div>
          <div>
            <el-button size="small" type="primary" @click="onSubmit"
              >查询</el-button
            >
            <el-button size="small" @click="handleReset">重置</el-button>
          </div>
        </el-col>
      </el-row>
      <!-- <el-divider class="bandProduct-search-divider"></el-divider>
      <div style="margin: 8px 0"></div> -->
    </el-form>
    <SetTagManage
      :append-to-body="true"
      :visible="tagVisible"
      :value="form.productTagValues"
      @ok="tagOk"
      @cancel="tagCancel"
    />
  </div>
</template>

<script>
import { includes } from "lodash";
import SetTagManage from "@/components/tag/setTagManage.vue";
import { getCityList, getAreaList } from "@/api/city";
import { getDictionaryListByKey } from "@/api/dectionary";

export default {
  components: {
    SetTagManage,
  },
  props: {
    categoryType: {
      type: Number,
      default: 1,
    },
    type: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      shelfTime: "",
      updateTime: "",
      areaList: [],
      cityList: [],
      selectProvinceList: [],
      selectCityList: [],
      levelList: [],
      form: {
        productCode: "",
        productName: "",
        productLevel: "",
        region: "",
        productProvince: "",
        productCity: "",
        productCityIdList: [],
        productTagIdList: [],
        serviceType: "",
        productTagValues: [],
      },
      fold: false,
      tags: [{ name: "标签一" }, { name: "标签二" }],
      tagVisible: false,
      provinceList: [],

      townList: [],
    };
  },
  watch: {
    // eslint-disable-next-line object-shorthand
    "form.region"(newVal) {
      if (newVal) {
        const province = [];
        const index = this.areaList.findIndex(
          (item) => item.regionId === newVal
        );
        const provinceIds = this.areaList[index].district;
        this.provinceList.forEach((p, pIndex) => {
          if (includes(provinceIds, p.districtId)) {
            province.push(p);
          }
        });
        this.form.productProvince = "";
        this.selectProvinceList = province;
      } else {
        this.form.productProvince = "";
        this.selectProvinceList = [];
        this.selectCityList = [];
      }
    },
    // eslint-disable-next-line object-shorthand
    "form.productProvince"(newVal) {
      if (newVal) {
        const cities = [];
        this.cityList.forEach((item) => {
          if (parseInt(item.parentId, 10) === parseInt(newVal, 10)) {
            cities.push(item);
          }
        });
        this.selectCityList = cities;
        this.form.productCity = "";
      } else {
        this.form.productCity = "";
        this.selectCityList = [];
      }
    },
    productCity(newVal) {
      if (newVal) {
        this.form.productCityIdList = [newVal];
      } else {
        this.form.productCityIdList = [];
      }
    },
  },
  created() {
    this.getArea();
    this.getProvices();
    this.getCities();
    this.getProductLevelList();
    // getCityList({ data: { districtType: 1 } }).then((res) => {
    //   this.provinceList = [...res];
    // });
  },
  methods: {
    async getProductLevelList() {
      try {
        const res = await getDictionaryListByKey({
          data: { key: "product_level" },
        });
        this.levelList = res.data.childs;
      } catch (e) {}
    },
    async getArea() {
      const params = { data: {} };
      const res = await getAreaList(params);
      this.areaList = res.data;
    },
    /**
     * 获取省份列表
     */
    async getProvices() {
      const params = { data: { districtType: 1 } };
      const response = await getCityList(params);
      this.provinceList = response;
    },
    /**
     * 获取所有城市
     * @param {*}
     */
    async getCities() {
      const params = { data: { districtType: 2 } };
      const response = await getCityList(params);
      this.cityList = response;
    },
    onSubmit() {
      this.$emit("search", this.form);
    },
    handleReset() {
      this.form.productCode = "";
      this.form.productLevel = "";
      this.form.productName = "";
      this.form.region = "";
      this.form.productProvince = "";
      this.form.productCity = "";
      this.form.serviceType = "";
      this.$emit("search", this.form);
    },
    checkFold() {
      this.fold = !this.fold;
    },
    tagOk(e) {
      this.form.productTagValues = [...e];
      this.form.productTagIdList = e.map((item) => item.id);
      this.tagVisible = false;
    },
    tagCancel() {
      this.tagVisible = false;
    },
  },
};
</script>
//
<style lang="less" scoped>
// @import './search.less';
//
.bandProduct-search {
  .el-form-item {
    margin-bottom: 10px !important;
  }
  .bandProduct-search-divider {
    margin: 5px 0 !important;
  }
  .tags {
    height: 40px;
    overflow: auto;
  }
  .tags::-webkit-scrollbar {
    width: 5px;
    height: 10px;
    /**/
  }
  .tags::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 5px;
    display: none;
  }
  .tags::-webkit-scrollbar-thumb {
    background: #bfbfbf;
    border-radius: 10px;
  }
}
.col {
  display: flex;
  align-items: center;
  &-tag {
    flex: 1;
    overflow: hidden;
    margin-right: 20px;
  }
}
::v-deep .el-form-item {
  margin-right: 0 !important;
}
</style>
