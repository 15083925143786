<template>
  <div class="addQuestionnaire">
    <div class="title">
      <ty-item-title
        title="新增问卷"
        :margin-left="true"
        :cancel-button="true"
        :save-button="true"
        :finish-button="true"
        :save-draft="true"
        @cancel="cancel"
        @save="save"
        @finish="finish"
      >
      </ty-item-title>
    </div>

    <div>
      <el-form
        ref="dataForm"
        :rules="rules"
        :model="form"
        label-width="140px"
        label-position="right"
        class="content-container"
      >
        <div class="left">
          <el-form-item label="问卷名称：" prop="questionnaireName">
            <el-input
              v-model.trim="form.questionnaireName"
              style="width: 210px"
              placeholder="请输入问卷名称"
              show-word-limit
              maxlength="10"
            ></el-input>
          </el-form-item>
          <el-form-item label="新增方式：" prop="addType">
            <div>
              <el-radio v-model="form.addType" :label="0">普通新增</el-radio>
              <el-radio v-model="form.addType" :label="1">模板新增</el-radio>
            </div>
            <div v-if="form.addType == 1" class="choseTemplate">
              <span> <span style="color: #e86247">*</span> 选择模板：</span>
              <span class="please-chose" @click="choseTemplate">
                <span>{{
                  Object.keys(selectedTemplate).length
                    ? selectedTemplate.templateName
                    : '请选择模板'
                }}</span>
                <span class="change"
                  >更改模板
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="导入模板会替换开头语和问题项"
                    placement="top"
                  >
                    <i style="color: #878787" class="el-icon-warning-outline"></i>
                  </el-tooltip>
                </span>
              </span>
            </div>
          </el-form-item>
          <el-form-item label="开头语：" prop="openingWords">
            <el-input
              v-model="form.openingWords"
              type="textarea"
              placeholder="请输入问卷开头语"
              :rows="8"
              maxlength="200"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="绑定单品：" prop="products">
            <el-button
              type="primary"
              plain
              icon="el-icon-plus"
              size="mini"
              style="background: #e9effc"
              @click="choseProduct"
              >选择单品</el-button
            >
            <div v-if="form.products.length" class="product-area">
              <el-tag
                v-for="(tag, index) in form.products"
                :key="index"
                class="product-item"
                size="small"
                type="info"
                closable
                :disable-transitions="true"
                @close="deleteProduct(index)"
              >
                {{
                  tag.relationProductName
                    ? `${tag.productCode} / ${
                        tag.relationServiceType === 1 ? '到店拍摄' : '上门拍摄'
                      } / ${tag.relationProductName}`
                    : `${tag.categoryName}`
                }}
              </el-tag>
            </div>
          </el-form-item>
        </div>

        <div class="right">
          <el-form-item label="问题：" prop="problems">
            <div class="question-list">
              <div class="list-item">
                <el-button
                  type="primary"
                  plain
                  icon="el-icon-plus"
                  size="mini"
                  style="background: #e9effc; flex: 1; height: 40px"
                  @click="choseQuestion"
                  >选择问题</el-button
                >

                <div class="delete"></div>
              </div>

              <div v-if="form.problems.length" class="question-area">
                <div v-for="(item, index) in form.problems" :key="index" class="list-item">
                  <div class="question-item" :class="currentQuestion == index ? 'item-shadow' : ''">
                    <span style="font-size: 14px; margin-right: 50px; white-space: nowrap">
                      <span class="index">{{ index + 1 }}.</span>
                      <span class="question-name">&nbsp;&nbsp;{{ item.content }}</span>
                    </span>

                    <span style="font-size: 12px; white-space: nowrap">
                      <span class="tag orange-tag">{{
                        item.problemType == '0'
                          ? '自定义'
                          : item.problemType == '1'
                          ? '数据字典'
                          : item.problemType == '2'
                          ? '标签库'
                          : item.problemType == '3'
                          ? '拍摄产品'
                          : '拍摄人'
                      }}</span>

                      <span class="tag blue-tag">{{
                        item.type == '0'
                          ? '单选题'
                          : item.type == '1'
                          ? '多选题'
                          : item.problemType == '2'
                          ? '日期编辑题'
                          : item.type == '3'
                          ? '填空题'
                          : '图片编辑题'
                      }}</span>
                    </span>
                  </div>

                  <div class="delete">
                    <i
                      class="el-icon-delete delete-icon"
                      :class="currentQuestion == index ? 'red-icon' : ''"
                      @click="deleteQuestion(index)"
                      @mouseover="currentQuestion = index"
                      @mouseleave="currentQuestion = null"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>
        </div>
      </el-form>
    </div>

    <el-dialog
      v-if="choseQuestionVisible"
      :visible.sync="choseQuestionVisible"
      title="选择问题"
      width="60%"
      class="questionDialog"
      :destroy-on-close="true"
    >
      <choseQuestion ref="question" :question-list="form.problems"></choseQuestion>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="choseQuestionVisible = false">取 消</el-button>
        <el-button type="primary" size="mini" @click="questionOk">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      v-if="choseTemplateVisible"
      :visible.sync="choseTemplateVisible"
      title="选择模板"
      width="50%"
      :close-on-click-modal="false"
    >
      <choseTemplate
        :selected-template="selectedTemplate"
        @closeChoseTemplate="closeChoseTemplate"
      ></choseTemplate>
    </el-dialog>

    <chose-product
      ref="product"
      :visible="productVisible"
      :product-list="form.products"
      :questionnaire-id="id"
      is-questionnaire="1"
      @cancel="productCancel"
      @ok="productOk"
    ></chose-product>
  </div>
</template>

<script>
import tyItemTitle from '@/components/ty-item-title/index.vue'
import choseTemplate from './choseTemplate.vue'
import choseQuestion from './choseQuestion.vue'
import choseProduct from './choseProduct.vue'
import { saveQuestionnaire, getQuestionnaireById } from '@/api/questionnaire'

export default {
  components: {
    tyItemTitle,
    choseTemplate,
    choseQuestion,
    choseProduct,
  },
  data() {
    const checkTemplate = (rule, value, callback) => {
      if (value === 1) {
        if (!Object.keys(this.selectedTemplate).length) {
          callback(new Error('请选择一个模板'))
        }
        callback()
      } else {
        callback()
      }
    }
    return {
      choseQuestionVisible: false,
      choseTemplateVisible: false,
      currentQuestion: null,
      productVisible: false,
      selectedTemplate: {},
      form: {
        questionnaireName: '',
        openingWords: '',
        addType: 0,
        problems: [],
        products: [],
      },
      rules: {
        questionnaireName: [{ required: true, message: '请输入问卷名称', trigger: 'blur' }],
        openingWords: [{ required: true, message: '请输入开头语', trigger: 'blur' }],
        addType: [{ validator: checkTemplate, trigger: 'blur' }],
        problems: [{ required: true, message: '请选择问题', trigger: 'blur' }],
      },
      id: '',
    }
  },
  watch: {
    'form.addType': {
      handler(val) {
        this.form.questionnaireName = ''
        this.form.openingWords = ''
        this.form.problems = []
        this.form.products = []
      },
    },
  },
  async created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id

      const res = await getQuestionnaireById({ data: this.id })
      const { questionnaireName, openingWords, problems, products } = res.data
      this.form = {
        questionnaireName,
        openingWords,
        addType: 0,
        problems,
        products: (products || []).map((el) => ({
          relationProductName: el.tyProductName,
          productCode: el.tyProductCode,
          relationProductId: el.tyProductId,
        })),
      }
    }
  },
  methods: {
    choseQuestion() {
      this.choseQuestionVisible = true
    },
    choseTemplate() {
      this.choseTemplateVisible = true
    },
    closeChoseTemplate(selectList) {
      this.choseTemplateVisible = false
      this.selectedTemplate = selectList[0]
      const { openingWords, problems } = selectList[0]
      this.form.openingWords = openingWords
      this.form.problems = problems
    },
    deleteProduct(index) {
      this.form.products.splice(index, 1)
    },
    deleteQuestion(index) {
      this.form.problems.splice(index, 1)
    },
    choseProduct() {
      this.productVisible = true
    },
    productCancel() {
      this.productVisible = false
    },
    productOk(selectList) {
      this.form.products = [...selectList]
      this.productVisible = false
    },
    cancel() {
      this.$router.push({
        path: '/questionnaireManage/index',
        query: { listType: 'questionnaire' },
      })
    },
    async save() {
      const { questionnaireName, products, problems, openingWords, addType } = this.form

      const reqObj = {
        data: {
          questionnaireName,
          isSave: 0,
          products: products.map((el) => ({
            tyProductId: el.relationProductId,
            tyProductCode: el.productCode,
            tyProductName: el.relationProductName,
          })),
          problems: problems.map((el) => ({ problemId: Number(el.id) })),
          openingWords,
          addType,
        },
      }

      if (this.id) {
        reqObj.data.id = this.id
      }

      const res = await saveQuestionnaire(reqObj)
      if (res.success) {
        this.$message.success('保存成功')
        this.cancel()
      }
    },
    async finish() {
      const { questionnaireName, products, problems, openingWords, addType } = this.form

      const reqObj = {
        data: {
          questionnaireName,
          isSave: 1,
          products: products.map((el) => ({
            tyProductId: el.relationProductId,
            tyProductCode: el.productCode,
            tyProductName: el.relationProductName,
          })),
          problems: problems.map((el) => ({ problemId: el.id })),
          openingWords,
          addType,
        },
      }

      if (this.id) {
        reqObj.data.id = this.id
      }

      this.$refs.dataForm.validate(async (valid) => {
        if (!valid) return

        this.$confirm('彻底完成编辑，不可再次编辑,确定完成吗？', '提示')
          .then(async () => {
            const res = await saveQuestionnaire(reqObj)
            if (res.success) {
              this.$message.success('保存成功')
              this.$router.replace({
                path: '/questionnaireManage/index',
                query: { listType: 'questionnaire' },
              })
            }
          })
          .catch(() => {})
      })
    },
    questionOk() {
      if (!this.$refs.question.selectList.length) {
        this.$message.error('至少选择一个问题')
        return
      }
      this.form.problems = this.$refs.question.selectList
      this.$refs.dataForm.validateField('problems')
      this.choseQuestionVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.addQuestionnaire {
  background: #ffffff;
  height: 100vh;
  width: 100%;
  overflow: hidden;

  .content-container {
    display: flex;
    height: 100vh;

    .left {
      flex: 1;
      padding-right: 60px;
      border-right: 1px solid #e0e0e0;
      box-sizing: border-box;
      overflow-y: auto;
      height: 100%;
      padding-top: 24px;

      .choseTemplate {
        display: inline-block;
        width: 100%;
        background: #fafafa;
        border: 1px solid #ededed;
        padding: 20px;
        display: flex;

        .please-chose {
          padding-left: 10px;
          border: 1px solid #e0e0e0;
          display: flex;
          justify-content: space-between;
          flex: 1;
          border-radius: 4px;
          cursor: pointer;

          .change {
            padding: 0px 25px;
            background: #f5f5f5;
            color: #2861e2;
            font-size: 14px;
            border-left: 1px solid #e0e0e0;
            box-sizing: border-box;
          }
        }
      }

      .product-area {
        .product-item {
          margin-right: 10px;
          margin-bottom: 5px;
        }
      }
    }

    .right {
      flex: 1;
      height: 100%;
      padding-top: 24px;

      .text-button {
        cursor: pointer;
        color: #e86247;
      }

      .question-list {
        display: flex;
        flex-direction: column;
        padding-top: 5px;

        .list-item {
          margin-bottom: 16px;
          display: flex;
        }

        .question-area {
          display: flex;
          flex-direction: column;
          height: 60vh;
          overflow-y: auto;
        }

        .question-area::-webkit-scrollbar {
          width: 6px;
        }

        .question-area::-webkit-scrollbar-thumb {
          border-radius: 3px;
          background: #e0e3e7;
        }

        .question-item {
          border: 1px solid #eaeaea;
          border-radius: 4px;
          padding: 0 16px;
          display: flex;
          justify-content: space-between;
          flex: 1;

          .index {
            color: #a4a8ac;
          }

          .tag {
            padding: 4px 6px;
            margin-right: 6px;
            white-space: nowrap;
          }

          .tag:last-child {
            margin-right: 0;
          }

          .orange-tag {
            background: rgba(255, 132, 38, 0.08);
            color: rgba(241, 125, 37, 0.8);
          }

          .blue-tag {
            background: rgba(38, 144, 255, 0.08);
            color: rgba(38, 144, 255, 0.8);
          }
        }

        .item-shadow {
          box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.08);
        }

        .delete {
          width: 44px;
          display: flex;
          justify-content: center;
          align-items: center;

          .delete-icon {
            cursor: pointer;
          }

          .red-icon {
            color: #e86247;
          }
        }
      }
    }
  }

  .questionDialog {
    ::v-deep .el-dialog__footer {
      border-top: 1px solid #ededed !important;
    }
  }

  ::v-deep .el-button--primary.is-plain {
    color: #2861e2;
    border: 1px solid #2861e2;
    background: #fff;
  }

  ::v-deep .el-dialog__header {
    background: #fff;
    border-bottom: 1px solid #ededed;
    border-radius: 6px 6px 0 0;
  }

  ::v-deep .el-dialog__body {
    // border-radius: 0 0 6px 6px;
    padding: 20px;
  }

  ::v-deep .el-dialog {
    border-radius: 6px;
  }
}
</style>
