<template>
  <div class="choseTemplate">
    <div class="search-area">
      <el-input
        v-model="searchName"
        prefix-icon="el-icon-search"
        placeholder="请输入模板ID/名称"
        style="width: 240px"
      ></el-input>
      <el-button style="margin-left: 20px" size="mini" type="primary" @click="search"
        >查询</el-button
      >
    </div>

    <div class="table-area" @scroll.passive="scrollEvent($event)">
      <el-table ref="chackTable" :data="templateList" border @select="handleSelect">
        <el-table-column type="selection"></el-table-column>
        <el-table-column prop="id" label="模板ID" width="120"></el-table-column>
        <el-table-column prop="templateName" label="名称" width="170"></el-table-column>
        <el-table-column prop="openingWords" label="开头语"></el-table-column>
      </el-table>
    </div>

    <div class="bottom-button">
      <el-button type="primary" size="mini" @click="submit">确定</el-button>
    </div>
  </div>
</template>

<script>
import { getTemplateList } from '@/api/questionnaire'

export default {
  props: {
    selectedTemplate: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      searchName: '',
      templateList: [],
      selectList: [],
      timer: null,
      pageNumber: 1,
      isScroll: false,
    }
  },
  async mounted() {
    await this.getList()
  },
  beforeDestroy() {
    // Object.assign(this._data, this.$options.data());
  },
  methods: {
    handleSelectionChange(val) {
      // if (val.length === 0) {
      //   this.selectList = val;
      //   return;
      // }

      // if (val.length === 1) {
      //   this.selectList = val;
      //   // this.$emit("closeChoseTemplate", this.selectList);
      //   return;
      // }

      this.$refs.chackTable.clearSelection()
      this.$refs.chackTable.toggleRowSelection(val[val.length - 1])
      this.selectList = [val[val.length - 1]]
    },
    handleSelect(selection, row) {
      if (selection.length === 0) {
        this.$refs.chackTable.clearSelection()
        this.selectList = []
        return
      }

      this.$refs.chackTable.clearSelection()
      this.$refs.chackTable.toggleRowSelection(row)
      const newArr = []
      newArr.push(row)
      this.selectList = newArr

      // this.$emit("closeChoseTemplate", this.selectList);
    },
    scrollEvent(e) {
      if (e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight) {
        // 防止第二次触发
        if (this.timer) {
          this.timer = null
          return
        }
        this.isScroll = true

        this.timer = setTimeout(async () => {
          this.pageNumber += 1

          await this.getList()
          this.isScroll = false
          clearTimeout(this.timer)
        }, 500)
      }
    },
    async getList() {
      const reqObj = {
        data: {
          templateNoOrName: this.searchName.trim(),
        },
        pageNumber: this.pageNumber,
        pageSize: 13,
      }

      const res = await getTemplateList(reqObj)

      if (res.success) {
        if (this.isScroll) {
          res.data.content.forEach((el) => {
            if (!this.templateList.find((el2) => el2.id === el.id)) this.templateList.push(el)
          })

          this.$nextTick(() => {
            this.toggleSelection(this.selectedTemplate)
          })

          return
        }

        this.templateList = res.data.content
        this.$nextTick(() => {
          this.toggleSelection(this.selectedTemplate)
        })
      }
    },
    async search() {
      this.pageNumber = 1
      await this.getList()
    },
    toggleSelection(row) {
      const objCopy = this.templateList.find((el) => el.id === row.id)

      this.$refs.chackTable.toggleRowSelection(objCopy, true)
    },
    submit() {
      this.$emit('closeChoseTemplate', this.selectList)
    },
  },
}
</script>

<style lang="scss" scoped>
.choseTemplate {
  .search-area {
    margin-bottom: 20px;
    display: flex;
  }

  .table-area {
    overflow-y: auto;
    height: 500px;
  }

  .bottom-button {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }

  ::v-deep th.is-leaf {
    .el-checkbox {
      visibility: hidden;
    }
  }
}
</style>
