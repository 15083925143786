import request from "@/utils/request"

// 层级查询城市
export function getCityList(data) {
  return request({
    url: "misc-web-api/common/district/queryById",
    method: 'POST',
    data,
  })
}

// 获取区域
export function getAreaList(data) {
  return request({
    url: "misc-web-api/common/region/regionList",
    method: 'POST',
    data,
  })
}